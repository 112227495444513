<script setup lang="ts">
import { Head } from "@inertiajs/vue3";
import BaseLayout from "@/Layouts/BaseLayout.vue";
import Wordmark from "@/Components/Wordmark.vue";

defineOptions({
  layout: BaseLayout,
});

interface Props {
  title: string;
}

defineProps<Props>();

const appName = import.meta.env.VITE_APP_NAME;
</script>

<template>
  <Head>
    <title>{{ title }}</title>
  </Head>
  <div
    class="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8"
  >
    <div class="sm:mx-auto sm:w-full sm:max-w-sm">
      <Wordmark class="mx-auto h-8 w-auto" aria-hidden="true" />
      <span class="sr-only">{{ appName }}</span>
      <h2
        class="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900"
      >
        {{ title }}
      </h2>
    </div>

    <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
      <slot />
    </div>
  </div>
</template>
